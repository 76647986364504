var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("div", { staticClass: "title" }, [
        _vm._v("Please wait. Your email is being verified"),
      ]),
      _vm.verificationStatus === "ongoing"
        ? _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          })
        : _vm._e(),
      _vm.verificationStatus === "failed"
        ? _c("div", { staticClass: "error" }, [
            _vm._v(" Email verification has failed. Please try again "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }