<template>
  <v-container>
    <div class="title">Please wait. Your email is being verified</div>
    <v-progress-circular
      indeterminate
      color="primary"
      v-if="verificationStatus === 'ongoing'"
    ></v-progress-circular>
    <div class="error" v-if="verificationStatus === 'failed'">
      Email verification has failed. Please try again
    </div>
  </v-container>
</template>

<script>
import { delayPromise } from "/global/utils/helpers";

export default {
  name: "EmailVerify",
  props: {
    token: {
      type: String,
      default: undefined
    }
  },
  watch: {
    $route: "verify"
  },
  async created() {
    await this.verify();
  },
  data() {
    return {
      verificationStatus: "ongoing",
      called: 0
    };
  },
  methods: {
    async verify() {
      try {
        await this.$store.dispatch("user/verifyEmail", {
          token: this.token
        });
        await delayPromise(1000);
        await this.$router.replace("/accounts");
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          "Failed to verify account. Try by sending verify link again."
        ]);
        this.verificationStatus = "failed";
      }
    }
  },
  computed: {}
};
</script>

<style></style>
